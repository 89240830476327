import React from "react";
import "./App.css";
import "./assets/css/globals.scss";
import Config from "./config/Config";

function App() {
  return (
    <div className="App">
      <Config />
    </div>
  );
}

export default App;
