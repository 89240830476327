import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
const Home = lazy(() => import("../pages/Home"));
const Home2 = lazy(() => import("./../pages/Home2"));
const PrivacyPolicy = lazy(() => import("./../pages/PrivacyPolicy"));
const Disclaimer = lazy(() => import("./../pages/Disclaimer"));
const TermAndCondition = lazy(() => import("./../pages/TermAndCondition"));
const CookiePolicy = lazy(() => import("./../pages/CookiePolicy"));
const RefundPolicy = lazy(() => import("./../pages/RefundPolicy"));
const Config = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* <------Regular Routes------> */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/our-services" element={<Home2 />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermAndCondition />}
          ></Route>
          <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
          <Route path="/refund-policy" element={<RefundPolicy />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
